import schttp from 'public/src/services/schttp'
const { BASE_URL } = require('../const')


const http = async (data) => {
  let result = null
  try {
    const res = await schttp({
      ...data,
      url: data.url.includes(BASE_URL) ? data.url : BASE_URL + data.url
    })
    const { info = {}, code } = res || {}
    if (typeof code === 'undefined' || code == 1002) {
      result = res
    } else {
      result = code == 0 ? info : null
    }
  } catch(e) {
    console.warn(e, '接口错误', data)
  }
  return result
}

// 中间层融合bffHttp封装
const bffHttp = async (data, loadingConfig = {}) => {
  let result = null
  try {
    const res = await schttp({
      ...data,
      url: data.url,
      useBffApi: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      schttp: {
        loadingConfig
      }
    })
    const { info = {}, code } = res || {}
    if (typeof code === 'undefined' || code == 1002) {
      result = res
    } else {
      result = code == 0 ? info : null
    }
  } catch(e) {
    console.warn(e, '接口错误', data)
  }
  return result
}

// 获取分组的token
export function getShareGroupTokenApi(params) {
  return bffHttp({
    url: '/user/wishlist/getEncryptToken',
    method: 'post',
    data: params
  })
}

// 获取分享页面数据
export function getShareGroupList({ page, size, token, isBff, isBffLangAndShineClube } = {}) {
  return http({
    url: '/groups/share/get',
    method: 'get',
    params: { page, size, token, isBff, isBffLangAndShineClube }
  })
}


// 获取分享页面数据
export function getShareGroupListBff({ page, size, token } = {}) {
  return bffHttp({
    url: '/user/wishlist/shareGoodsList',
    method: 'post',
    data: { page, limit: size || 20, token }
  })
}
