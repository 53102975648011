import schttp from 'public/src/services/schttp'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { bffHttp } from './common'

const { language } = gbCommonInfo
const BASE_URL = '/api/user/wishlist_v2'

const http = async (data, loadingConfig = {}) => {
  let result = null
  try {
    const res = await schttp({
      ...data,
      url: data.url.includes(BASE_URL) ? data.url : BASE_URL + data.url,
      schttp: {
        loadingConfig
      }
    })
    const { info = {}, code } = res || {}
    if (typeof code === 'undefined' || code == 1002) {
      result = res // 没有code直接返回结果
    } else {
      result = code == 0 ? info : null
    }
  } catch(e) {
    SToast(language.SHEIN_KEY_PWA_21283)
    console.warn(e, '接口错误', data)
  }
  return result
}

// 获取分组列表
export const getGroupListsApi = () => {
  return bffHttp({
    url: '/user/wishlist/groupList',
    method: 'post',
  })
  // old 
  // http({
  //   url: '/group/list/get',
  //   method: 'get',
  // })
}

// 获取分组商品列表
export const getGroupGoodsApi = (params = {}, loadingConfig = {}) => {
  return http({
    url: '/group/goods/get',
    method: 'get',
    params,
  }, 
  loadingConfig
  )
}

// 删除分组
export function deleteGroupApi(data = {}) {
  return bffHttp({
    url: '/user/wishlist/groupDel',
    method: 'post',
    data
  })
}

// 分组批量删除商品
export function groupDelGoodsApi(data) {
  return bffHttp({
    url: '/user/wishlist/groupDelGoods',
    method: 'post',
    data
  })
}

// 编辑分组
export async function groupUpdateApi(data) {
  try {
    const result = await schttp({
      url: '/user/wishlist/groupUpdate',
      method: 'post',
      data,
      useBffApi: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    if (`${result.code}` === '0') {
      return result
    } else if (`${result.code}` === '400414') { // 名称重复
      SToast(language.SHEIN_KEY_PWA_17512)
    } else {
      SToast(language.SHEIN_KEY_PWA_18152)
    }
  } catch(e) {
    SToast(language.SHEIN_KEY_PWA_21283)
  }
}

// 获取分组创建默认名称
export function getGroupDefaultNameApi() {
  return bffHttp({
    url: `/user/wishlist/getWishListDefaultName`,
    method: 'post',
    params: {}
  })
}

// 添加分组
export async function groupCreateApi(data = {}) {
  try {
    const result = await schttp({
      url: `/user/wishlist/groupAdd`,
      method: 'post',
      data,
      useBffApi: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    if (`${result.code}` === '0') {
      return result.info
    } else if (`${result.code}` === '400414') { // 名称重复
      SToast(result?.tips || language.SHEIN_KEY_PWA_17512)
    }
  } catch(e) {
    SToast(language.SHEIN_KEY_PWA_21283)
  }
}

// 获取可选分组列表
export function getGroupOptionalListApi(params = {}, loadingConfig) {
  return bffHttp({
    url: '/user/wishlist/getOptionalList',
    method: 'post',
    data: params
  }, loadingConfig)
}

// 新增商品到分组
export function groupAddGoodsApi({ groupId, goodsIds = [] } = {}) {
  return bffHttp({
    url: '/user/wishlist/groupAddGoods',
    method: 'post',
    data: {
      groupId,
      goodsIds: goodsIds.join(',')
    }
  })
}

// 分组选择页，一行三图
export const getFilterListSelectApi = (params, loadingConfig = {}) => {
  return http({
    url: '/select/list/query',
    method: 'get',
    params,
  }, loadingConfig)
}

// 分组选择页，一行三图Bff
export const getFilterListSelectBffApi = (params, loadingConfig = {}) => {
  return bffHttp({
    url: '/user/get_wishlist_by_filter',
    method: 'get',
    params
  }, loadingConfig)
}
